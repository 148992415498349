<template>
  <div class="lead-details">
    <div class="container fluid">
      <div class="d-flex align-items-center py-3">
        <fd-button class="circle mr-2" @click="$router.go(-1)"
          ><i class="fas fa-chevron-left"></i
        ></fd-button>
        <h1>Lead Details</h1>
      </div>

      <div class="card p-3">
        <LeadDetail :lead="lead" :isCapped="isFollowingCapped"></LeadDetail>
      </div>

      <div
        class="d-flex"
        :style="{ position: 'fixed', bottom: '24px', right: '24px' }"
      >
        <fd-button
          v-if="canDropLead"
          class="round medium danger"
          icon="fas fa-trash"
          @click="openDropLeadModal"
        >
          Drop
        </fd-button>

        <fd-button
          v-if="isTaken && isMine"
          class="round medium main ml-2"
          icon="fas fa-house-circle-check"
          @click="toCreatePropertyFromLead"
        >
          Create Property
        </fd-button>

        <fd-button
          v-if="canFollowUp"
          class="round medium main ml-2"
          icon="fas fa-person-walking-arrow-right"
          @click="followUpLead"
        >
          Follow Up
        </fd-button>
      </div>
    </div>

    <!-- Drop Lead Modal -->
    <modal v-model="dropLeadModal.isActive">
      <DropLeadModalContent
        class="card p-3"
        @cancel="closeDropLeadModal"
        @drop="dropLead"
      ></DropLeadModalContent>
    </modal>
  </div>
</template>

<script>
import { lead as LeadAPI } from "@/api";
import { leadStatus } from "@/enums";

export default {
  components: {
    LeadDetail: () => import("@/modules/Lead/components/LeadDetail"),
    DropLeadModalContent: () =>
      import("@/modules/Lead/components/DropLeadModalContent")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      lead: {},
      isFollowingCapped: false,
      canDropPending: false,

      dropLeadModal: {
        isActive: false
      }
    };
  },
  computed: {
    canFollowUp() {
      return this.isPending && !this.isFollowingCapped;
    },
    canDropLead() {
      return (this.isPending && this.canDropPending) || this.isMine;
    },
    isPending() {
      return this.lead.status === leadStatus.PENDING;
    },
    isTaken() {
      return this.lead.status === leadStatus.ACCEPTED;
    },
    isMine() {
      return this.lead.agent?.user?.id == this.$auth.user().id;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getPermission();
      this.getLead();
      this.getFollowingLeads();
    },
    async getPermission() {
      this.canDropPending = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "lead.drop"
      );
    },
    async getLead() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await LeadAPI.getLead(this.$route.params.id);
        this.lead = data;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch lead details. Please try again later."
        });
      }
    },
    async getFollowingLeads() {
      try {
        let res = await LeadAPI.getFollowingLeads();
        this.isFollowingCapped = res.data.length >= 3;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch lead details. Please try again later."
        });
      }
    },
    async followUpLead() {
      let confirm = await this.$confirm({
        title: "Follow Up Lead",
        message: "Are you sure you want to follow up this lead?"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await LeadAPI.followUpLead(this.$route.params.id);
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Follow Up Lead",
            text: "You are currently following up this lead."
          });

          this.$router.push({ name: "FollowingLeads" });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Follow Up Lead",
            text: "Failed to follow up lead. Please try again later."
          });
        }
      }
    },

    openDropLeadModal() {
      this.dropLeadModal.isActive = true;
    },
    closeDropLeadModal() {
      this.dropLeadModal.isActive = false;
    },
    toCreatePropertyFromLead() {
      this.$router.push({
        name: "CreatePropertyFromLead",
        params: { id: this.$route.params.id }
      });
    },

    async dropLead(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await LeadAPI.dropLead(this.$route.params.id, payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Lead Dropped",
          text: "Lead has been dropped successfully."
        });
        this.$store.commit("setIsLoading", false);
        this.closeDropLeadModal();
        this.$router.push({ name: "PublicLeads" });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
